@import "../../variables.scss";

.about-page{
    overflow-x: hidden;

    .title{
        position: relative;
        h3 {
            position: absolute;
            top: 0;
            left: 0;
           
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
        h1{
           
            opacity: 0.1;
            font-weight: 100;
            letter-spacing: 0.25em;
        }
    }

    .our-mission{
        background-color: $secondary-main;
        width:100%;
        padding:64px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
    
        h3{
            color: $neutral-white;

        }

        h1{
            color: $neutral-white;
        }

        .hero-wrapper{
            display: flex;
            flex-direction: column-reverse;
            gap: 16px;
            align-items: center;
            justify-content: center;
            // padding-inline: 15%;
            max-width: 1300px;
            margin-inline: auto;
            padding-block: 5rem;

            @media (min-width: 764px) {
                flex-direction: row;
                gap: 32px;
                padding-block: 0;
            }

            .about-text-our-mission{
                display: flex;
                flex-direction: column;
                p{
                    color: $neutral-white;
                    font-size: 14px;
                }
            }


            img{
                width: 100%;
            }

            .outlined-button{
                background-color: $primary-main;
                color: white;
                border: 1px solid $primary-main;
                width: 50%;
                cursor: pointer;
                transition: .5s;
                margin-top: 16px; 
                
            }
            .outlined-button:hover{
                color: #D1EBF5;
                border-color: #D1EBF5
                
            }
        }


    }

    .our-vision{
        padding:16px;
        text-align: center;
        display: grid;
        gap: 16px;
        .vision-board{
            display: grid;
            gap:16px;
            grid-template-columns: 1fr 1fr;
            place-items: center;
            margin-inline: auto;
            max-width: 1300px;
            width: 100%;

            .vision-item{
                border: 1px solid $neutral-lighter-grey;
                border-radius: 11px;
                padding:8px;
                display: grid;
                gap:8px;
                justify-items: center;
                
                @media (min-width: 1024px) {
                    width: 100%;
                    max-width: 600px;
                    margin: 8px;
                }

                h3{
                    font-size: 16px;
                }

                p{
                    font-size: 12px;
                }
            }
        }



    }

    .green-statement{
        background-color: $neutral-silver;
        padding:16px;
        text-align: center;
        display: grid;
        gap: 16px;
        justify-items: center;

        
        h1{
            font-size: 26px;
        }

        .green-wrapper{
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: center;
            justify-content: center;
            width: 90%;
            margin-inline: auto;
            max-width: 1300px;
            padding: 16px;

            @media (min-width: 764px) {
                flex-direction: row;
                gap: 32px;
                padding: 32px;

                img{
                    width: 50%;
                }
            }

            .green-item-wrapper{
                display: flex;
                flex-direction: column;
                gap: 16px;
                justify-content: center;

                @media (min-width: 764px) {
                    gap:48px;
                }
            }
        }

        .green-item{
            gap: 8px;
            border-radius: 11px;
            padding: 32px;
            border: 1px solid $neutral-lighter-grey;
            align-items: center;
            
            svg{
                height:50px;
                width:50px;
            }

            p{
                font-size:14px;
                text-align: left;
            }

            @media (min-width: 764px) {
                display: flex;
            }
        }


    }

    .our-culture{
        display: grid;
        width: 90%;
        max-width: 1300px;
        margin: auto;
        gap: 16px;
        padding: 16px;
        text-align: center;
        .culture-item{
            padding: 32px;
            border: 1px solid $neutral-lighter-grey;
            border-radius: 11px;
            text-align: left;
            .toggle-bar{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            h1{
                font-size: 18px;
            }
            p{
                font-size: 14px;
                padding: .5rem 0 2rem 0;
            }

            .hidden{
                display: none;
            }
        }
    }

    .our-history{
        background-color: $neutral-silver;
        padding:16px;
        text-align: center;
        display: grid;
        gap: 16px;
        justify-items: center;
        
        h1{
            font-size: 26px;
        }

        p{
            font-size: 14px;
            padding:16px;
            border: 1px solid $neutral-lighter-grey;
            border-radius: 11px;
        }

        @media (min-width: 764px) {
            padding-block: 64px;
        }

        .our-history-wrapper{
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: center;
            justify-content: center;
            width: 90%;
            margin-inline: auto;
            max-width: 1300px;
            padding: 16px;
            
            p{
                font-size: 16px;
                
                > p{
                    display: flex;
                    white-space: pre-wrap;
                    border: none;
                }

                > :first-child{
                    display: flex;
                    justify-content: center;
                }
            }

            @media (min-width: 764px) {
                flex-direction: row-reverse;
                gap: 32px;
                padding: 32px;

                img{
                    width: 60%;
                }

                h1{
                    font-size: 32px;
                }
            }
        }
    }

}


