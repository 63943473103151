@import '../../variables.scss';

.terms-container {
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    @media (min-width: $medium) {
        margin-left: 25%;
        margin-right: 25%;
    }
}

noscript,
#navigation,
#banner,
#footer,
#intro,
#outtro,
.ImportantInfoDialog,
.headerSimplePreview,
.sectionMenuTop,
.selLicense,
.previewHead,
iframe,
iframe *,
.noMobile,
.woahbar,
.contractFootControls,
.previewFormatWarning {
    display: none;
}

#upsell,
#popupContainer,
#popupMask,
.contentBreak,
.contentBreakEnd {
    display: none !important;
}

#popupContainer,
#popupMask {
    visibility: hidden;
}

#wrapper,
#content {
    background: none;
    position: relative;
    display: block;
    width: auto;
}

#outputPage {
    display: block;
    padding: 3px;
    background: #fff;
    color: #000;
    position: relative;
    mso-style-parent: '';
    margin: 0;
    margin-bottom: 0.0001pt;
    mso-pagination: widow-orphan;
    tab-stops: -0.5in;
    font-size: 12pt;
    font-family: 'Times New Roman', serif;
    mso-fareast-font-family: 'Times New Roman';
    line-height: 18pt;
}

#outputPage {
    width: 100%;
    background: none;
}

#outputPage table {
    border-collapse: collapse;
}

#outputPage ol {
    list-style: decimal;
}

#outputPage ol ol {
    list-style: lower-alpha;
}

#outputPage ol ol ol {
    list-style: lower-roman;
}

#outputPage ol ol ol ol {
    list-style: decimal;
    list-style-position: inherit;
}

#outputPage br.pageBreak {
    page-break-before: always;
}

@media screen {
    #outputPage br.pageBreak {
        display: none;
    }
}

#outputPage .outputVersion2 p,
#outputPage .outputVersion2 ol,
#outputPage .outputVersion2 ul,
#outputPage .outputVersion2 table {
    margin-bottom: 0;
}

#outputPage .outputVersion2 ol,
#outputPage .outputVersion2 ul {
    margin-left: 0;
    padding-left: 0;
}

#outputPage .outputVersion2 li > div {
    display: table;
    *display: inline;
    *margin-left: -17px;
}

#outputPage .format-doc .outputVersion2 li,
#outputPage .format-rtf .outputVersion2 li,
#outputPage .format-docx .outputVersion2 li,
#outputPage .format-pdf .outputVersion2 li {
    margin-left: 18pt;
}

#outputPage .outputVersion2 {
    padding-top: 43pt;
}

.LDCopyright {
    font-size: 67%;
}

.LDCellCenter {
    text-align: center;
}

.LDCellPadLeft {
    padding-left: 100px;
}

.LDCellRight {
    text-align: right;
}

.LDResRentalBox {
    width: 100%;
}

.LDQuitclaimHead {
    height: 3in;
}

.LDWarrantyHead {
    height: 3in;
}

.LDQuitclaimHeadCA {
    height: 1in;
}

.LDWarrantyHeadCA {
    height: 1in;
}

.LDWarrantyHeadMO {
    height: 2in;
}

.LDQuitclaimHeadMO {
    height: 2in;
}

.SubHeadStyle {
    font-weight: bold;
    text-decoration: underline;
}

.SectionTitle {
    font-weight: bold;
    text-decoration: underline;
    margin: 1em 0;
}

.invoiceBorder {
    border: 1px solid #000;
}

.invoiceGreyBG {
    background-color: #ccc;
}

.LDBold {
    font-weight: bold;
}

.LDBackgroundOL {
    list-style-type: upper-alpha;
}

#bottomTabs,
#topTabs,
#introContent,
#loadingIndicator,
#questionpage,
#documentControls,
#questionpage {
    display: none;
}

#productContent,
#documentContent {
    width: 100%;
    border: 0;
    margin: 0;
}

#outputPage li {
    margin-bottom: 1em;
}

#outputPage li li {
    margin-bottom: 1em;
}

#outputPage li ol {
    margin-top: 1em;
}

#outputPage li ol li {
    margin-bottom: 1em;
}

#outputPage li.lh,
#outputPage li.lhl {
    margin-bottom: 0;
    list-style: none;
}

.FirstMajorListHeading {
    font-weight: bold;
    margin-bottom: 1.6em;
}

.FirstListHeading {
    font-weight: bold;
    text-decoration: underline;
    font-size: 12pt;
    margin: 1.4em 0 0.2em 2em; /* 2em should be set to left margin of lists */
}

.ListHeading {
    font-weight: bold;
    text-decoration: underline;
}

div.header,
div.firstHeader,
div.footer,
div.firstFooter {
    display: none;
}

.blankLine {
    display: block;
    border-bottom: solid 1px #000;
}

/* End Alerts/Confirms Inline Dialogs */
/* Mobile Scrollbars */
.ua-mobile .contractbody::-webkit-scrollbar,
.ua-mobile #contractbody::-webkit-scrollbar,
.ua-mobile .groupNavigationInner > ul::-webkit-scrollbar,
.ua-mobile #contentTopInnerDisclaimer::-webkit-scrollbar,
.ua-mobile #contentTopInnerPrivacy::-webkit-scrollbar,
.ua-mobile #contentTopInnerEditorialPolicy::-webkit-scrollbar,
.ua-mobile #contentTopInnerTerms::-webkit-scrollbar,
.ua-mac_os_x .contractbody::-webkit-scrollbar,
.ua-mac_os_x #contractbody::-webkit-scrollbar,
.ua-mac_os_x .groupNavigationInner > ul::-webkit-scrollbar,
.ua-mac_os_x #contentTopInnerDisclaimer::-webkit-scrollbar,
.ua-mac_os_x #contentTopInnerPrivacy::-webkit-scrollbar,
.ua-mac_os_x #contentTopInnerEditorialPolicy::-webkit-scrollbar,
.ua-mac_os_x #contentTopInnerTerms::-webkit-scrollbar,
.ua-mobile #DIVContractList::-webkit-scrollbar,
.mac_os_x #DIVContractList::-webkit-scrollbar,
.ua-mobile #tip::-webkit-scrollbar,
.ua-mac_os_x #tip::-webkit-scrollbar,
.ua-mac_os_x ul.ui-autocomplete::-webkit-scrollbar,
.ua-mobile ul.ui-autocomplete::-webkit-scrollbar,
.ua-mobile:not(.ua-ios) .inlineFooterDialog .ui-dialog-content::-webkit-scrollbar,
.addRecipientDialog::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.ua-mobile .contractbody::-webkit-scrollbar-thumb,
.ua-mobile #contractbody::-webkit-scrollbar-thumb,
.ua-mobile .groupNavigationInner > ul::-webkit-scrollbar-thumb,
.ua-mobile #contentTopInnerDisclaimer::-webkit-scrollbar-thumb,
.ua-mobile #contentTopInnerPrivacy::-webkit-scrollbar-thumb,
.ua-mobile #contentTopInnerEditorialPolicy::-webkit-scrollbar-thumb,
.ua-mobile #contentTopInnerTerms::-webkit-scrollbar-thumb,
.ua-mac_os_x .contractbody::-webkit-scrollbar-thumb,
.ua-mac_os_x #contractbody::-webkit-scrollbar-thumb,
.ua-mac_os_x .groupNavigationInner > ul::-webkit-scrollbar-thumb,
.ua-mac_os_x #contentTopInnerDisclaimer::-webkit-scrollbar-thumb,
.ua-mac_os_x #contentTopInnerPrivacy::-webkit-scrollbar-thumb,
.ua-mac_os_x #contentTopInnerEditorialPolicy::-webkit-scrollbar-thumb,
.ua-mac_os_x #contentTopInnerTerms::-webkit-scrollbar-thumb,
.ua-mac_os_x #DIVContractList::-webkit-scrollbar-thumb,
.ua-mobile #DIVContractList::-webkit-scrollbar-thumb,
.ua-mobile #tip::-webkit-scrollbar-thumb,
.ua-mac_os_x #tip::-webkit-scrollbar-thumb,
.ua-mac_os_x ul.ui-autocomplete::-webkit-scrollbar-thumb,
.ua-mobile ul.ui-autocomplete::-webkit-scrollbar-thumb,
.ua-mobile:not(.ua-ios) .inlineFooterDialog .ui-dialog-content::-webkit-scrollbar-thumb,
.addRecipientDialog::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* End special links */
/* General DCS Output Styles */
body li > ol:first-child,
body li > ul:first-child {
    margin-top: 0;
}

.ua-edge body li > ol:first-child,
.ua-edge body li > ul:first-child,
.ua-ie body li > ol:first-child,
.ua-ie body li > ul:first-child {
    margin-top: -24px;
}

body li ol,
body li ul {
    margin-top: 1em;
}

body li {
    margin-bottom: 1em;
}

body li.lh,
body li.lhl {
    margin-bottom: 0;
}

body th {
    font-weight: normal;
}

body td > p:first-child,
body th > p:first-child {
    margin-top: 0;
}

body td > p:last-child,
body th > p:last-child {
    margin-bottom: 0;
}

body br.pageBreak {
    display: none;
}

body .blankLine {
    display: block;
    border-bottom: solid 1px black;
}

.LD body #wrapper #content body #outputPage,
.LD body #wrapper #content body .documentContent,
.LD body #wrapper #content body .contract {
    padding: 2em;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    overflow-x: hidden;
}

.LD body body .header,
.LD body body .footer,
.LD body body .firstHeader,
.LD body body .firstFooter,
.LD body body .printWatermark {
    display: none;
}

.LD body #wrapper #contractbody ol,
.LD body #wrapper .contractbody ol {
    padding-left: 0;
}

.LD body #wrapper #contractbody li,
.LD body #wrapper .contractbody li {
    padding-left: 25px;
    margin-left: 15px;
}

@media only screen and (max-device-width: 1024px) {
    body {
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    }
}
