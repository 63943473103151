@import "../../variables.scss";

.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }
  
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    border-radius: 24px;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    transition: 0.4s;
    border-radius: 50%;
}
  
.slider.off {
    background-color: $neutral-white;
    border: 1px solid $neutral-l-grey;
}
  
.slider.off:before {
    background-color: $neutral-l-grey;
}
  
.slider.on {
    background-color: $primary-main;
}
  
.slider.on:before {
    background-color: $neutral-white;
    transform: translateX(24px);
}

.toggle-item{
    display: flex;
    align-items: center;
    gap:8px;
    
}