@import '../../variables.scss';

.welcome-section {
    text-align: center;
    h1 {
        color: $success-light;
        font-size: 6.3vw;
        white-space: nowrap;
        min-width: 5vw;
    }
    p {
        padding: 8px 0;
        font-size: 16px;
    }
}

.user-selector {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    margin-bottom: 2vh;
    background-color: $primary-main;
    color: white;
    border: 1px solid $primary-main;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;

    > button {
        border: none;
        color: $primary-main;
        font-size: 18px;
    }

    > p {
        padding: 0;
        color: white;
        white-space: nowrap;
        min-width: 3.5vw;
        font-size: 4vw;
    }

    .outlined-icon {
        path {
            stroke: $neutral-d-grey;
        }
    }

    .red-icon {
        path {
            fill: white;
            stroke: white;
        }
    }

    button {
        width: 100%;
    }
}

.hero-section {
    display: flex;
    flex-direction: column-reverse;
}

.hero {
    display: none;
}

// Desktop Responsiveness minwidth 768
@media (min-width: 768px) {
    .page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 85px;
        background-size: cover;
        .hero-section {
            display: flex;
            flex-direction: column;
        }
        .welcome-section {
            max-width: 650px;
            width: 650px;
            padding: 16px;
            background: rgba(255, 255, 255, 0.4);
            border: none;

            h1 {
                font-size: 28px;
            }
        }
        .user-selector {
            margin-top: 2vh;
            gap: 8px;
            > p {
                padding: 0;
                color: white;
                font-size: clamp(16px, 2vw, 27px);
            }
            button {
                width: 100%;
            }
        }
    }
}

@media (min-width: 1350px) {
    .page-container {
        display: flex;
        flex-direction: row;
        gap: 32px;
        justify-content: center;

        .hero {
            display: inline-block;
            width: 50%;
            max-width: 750px;
        }
    }
}
