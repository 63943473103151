@import "../../variables.scss";


.page-container{

    .contact-page-form{
        width: 90%;
    }
    .contact-page-info{
        width: 90%;
    }


    .contact-page{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding:32px;

        @media (min-width: 1024px){
            flex-direction: row;
            align-items: flex-start;
        }

        h1{
            font-size: 28px;
            margin-block: 8px;
        }
        h4{
            color: $neutral-l-grey;
            margin-block: 8px;
        }
        

        .subheader-text{
            color: $neutral-l-grey;
            font-size: 18px;
            font-weight: 600;
            margin: 32px 0px 16px 0px;
        }
        
        .contact-item{
            display: flex;
            width: 100%;
            box-sizing: border-box;
            gap:8px;
            margin-block: 16px;
            align-items: center;

            svg{
                background-color: $neutral-silver;
                padding:20px;
                border-radius: 5px;
            }

            .contact-item-right{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 4px;
                p{
                    color: $neutral-l-grey;
                    font-size: 14px;
                }
            }
        }

        .contact-form{
            margin-top: 8px;
            padding: 8px;
            background-color: $neutral-silver;
            display: grid;
            gap: 8px;
            width:100%;
            box-sizing: border-box;

            textarea{
                height:10rem;
            }
        }

    }
}