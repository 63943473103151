@import '../../../variables.scss';

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    height: 60px;
    background-color: #ffffff;
    padding: 0 20px;
    z-index: 999;

    img {
        margin: 0 auto;
        display: block;
        max-width: 250px;
    }

    svg {
        height: 30px;
        cursor: pointer;
    }

    .header-menu {
        display: none;
    }

    .register-button {
        background-color: $primary-main;
        color: #ffffff;
        border: none;
    }
}

.homepage-provider-login {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 5px;
    border: 4px solid $secondary-main;
    margin-top: 16px;

    p {
        color: $secondary-main;
        font-size: 16px;
    }

    // button {
    //     background-color: $primary-main;
    //     color: #ffffff;
    //     border: none;
    // }
}

// logo size for mobile
@media (max-width: 767px) {
    header {
        img {
            max-width: 150px;
        }
    }
}

// desktop fix

@media (min-width: 768px) {
    header {
        padding: 32px 8rem;

        img {
            margin: 0;
            max-height: 100px;
            height: 100px;
        }
    }
}

@media (min-width: 1350px) {
    header {
        svg {
            display: none;
        }

        .header-menu {
            display: flex;
            width: 100%;
            justify-content: space-around;

            .menu-items {
                list-style: none;
                display: flex;
                flex-direction: row;
                gap: 16px;
                align-items: center;
                li {
                    white-space: nowrap;
                }

                .menu-item-active {
                    font-weight: 800;
                    color: $primary-main;
                }
                .menu-item-active::before {
                    content: '';
                    display: inline-block;
                    width: 3px;
                    height: 13px;
                    background-color: $primary-main;
                    margin-right: 6px;
                }
            }

            .right-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;

                > div {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
            }

            button {
                white-space: nowrap;
                // font-size: 22px;
            }
        }
    }
}
