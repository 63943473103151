.check-address-container {
    flex-direction: column;
    gap: 8px;

    width: 60%;
    @media screen and (max-width: 1100px) {
        width: 100%;
    }

    .check-city-state {
        width: 100%;
        gap: 8px;

        > div:not(:first-child) {
            margin-top: 8px;
        }

        @media (min-width: 768px) {
            display: flex;
            > div {
                width: 50%;
                margin-top: 0 !important;
            }
        }


    }
}


// styling for the preferred payment method toggle
$activeShadow: 0 0 10px rgba(#66B3FB, .5);

/* MIXINS */
// @mixin focusOutline {outline: dotted 1px #CCC; outline-offset: .45rem;}
@mixin hideInput {width: 0; height: 0; position: absolute; left: -9999px;}
@mixin breakpoint($point) {
    @if $point == 1100 {
        @media (max-width: 1100px) { @content ; }
    }
    @else if $point == 800 {
        @media (max-width: 800px) { @content ; }
    }
}

/* STYLE THE HTML ELEMENTS (INCLUDES RESETS FOR THE DEFAULT FIELDSET AND LEGEND STYLES) */
// body, html {
//   margin: 0; padding: 1rem; box-sizing: border-box;
//   width: 100%; height: 100%;
//   font-size: 16px; font-family: $open-sans; 
//   -webkit-font-smoothing: antialiased;
//   background-color: #EEE;
// }
fieldset {
    flex-direction: column;
    margin: 0; 
    padding: 2rem; 
    box-sizing: border-box; 
    display: block;
    border: 0; 
    // border: solid 1px #CCC;
    min-width: 0;
    background-color: #FFF;
    legend {
        margin: 0 0 1rem; padding: 0;
        width: 100%; float: left; display: table;
        font-size: 1.5rem; line-height: 140%; font-weight: 600; color: #333;  
        + * {clear: both;}
    }
}
// body:not(:-moz-handler-blocked) fieldset {display: table-cell;}

/* TOGGLE STYLING */
.toggle {
    margin: 0 0 1rem;
    box-sizing: border-box;
    font-size: 0;
    display: flex; 
    flex-flow: row nowrap;
    justify-content: flex-start; 
    align-items: stretch;
    input {@include hideInput;}
    input + label {
        margin: 0; 
        padding: .75rem 2rem; 
        box-sizing: border-box;
        position: relative; 
        display: inline-block;
        border: solid 1px #DDD; 
        background-color: #FFF;
        font-size: 1rem; 
        line-height: 140%; 
        font-weight: 600; 
        text-align: center;
        box-shadow: 0 0 0 rgba(255,255,255,0);
        transition:   border-color .15s ease-out, 
            color .25s ease-out, 
            background-color .15s ease-out,
            box-shadow .15s ease-out;
    
    /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
    /*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
    /* ----- */
    
    &:first-of-type {border-radius: 6px 0 0 6px; border-right: none;}
    &:last-of-type {border-radius: 0 6px 6px 0; border-left: none;}
    }
    input:hover + label {border-color: #0093d0;}
    input:checked + label {
        background-color: #0093d0;
        color: #FFF;
        box-shadow: $activeShadow;
        border-color: #0093d0;
        z-index: 1;
    }
//   input:focus + label {@include focusOutline;}
//   input:focus + label {}

//   @include breakpoint(800) {
//     input + label {
//       padding: .75rem .25rem;
//       flex: 0 0 50%;
//       display: flex; justify-content: center; align-items: center;
//     }
//   }
}
