@import '../../../variables.scss';

.footer {
    background-color: $primary-main;
    color: white;
    padding: 16px;
    font-family: $font-family;

    h4 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
    }

    hr {
        border: 1px solid white;
        margin: 16px 0;
    }
    .footer-input {
        display: flex;
        gap: 8px;
        input {
            color: $neutral-l-grey;
            border-radius: 5px;
            border: 1px solid white;
            width: 100%;
            padding: 0 8px;
            font-size: 12px;
        }
        button {
            color: white;
            font-size: 14px;
            font-weight: 400;
            border: 1px solid $neutral-black;
            border-radius: 5px;
            padding: 8px 12px;
            background-color: $neutral-black;
        }
    }

    .footer-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: 12px;
            font-weight: 400;
        }

        .footer-links-right {
            display: flex;
            gap: 8px;
            a {
                color: white;
                font-size: 12px;
                cursor: pointer;
                &:hover {
                    color: $success-light;
                }
            }
        }
    }

    .footer-links-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        p {
            font-size: 12px;
            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                color: $success-light;
            }
        }

        .footer-links-right {
            display: flex;
            gap: 8px;
            flex-direction: column;
            a {
                color: white;
                font-size: 12px;
                cursor: pointer;
                text-decoration: underline;
                &:hover {
                    color: $success-light;
                }
            }
        }
    }
}

//desktop fix

@media (min-width: 768px) {
    .footer {
        padding: 48px 128px;
        display: flex;
        justify-content: space-between;
        hr {
            display: none;
        }

        .footer-links {
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
        }
    }
}
