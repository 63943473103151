@import "../../variables.scss";


.signup-form{
    display:flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    padding:8px;

    h4, p {
        text-align: center;
    }
    .input-group {
        width: 100%;
    }
    .input-wrapper{
        position:relative;
        width: 100%;
        box-sizing: border-box;
    
        .icon{
            position:absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
    
            &.car-icon{
                width: 22px;
                height: 22px;
            }
        }
    }
    .progress-bar{
        display: flex;
        gap:8px;
        align-items: center;
        width: 100%;
        box-sizing: border-box;

        @media screen and (min-width: 768px) {
            max-width: 60%;
        }

        .progress-bar-item{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-bottom: 3px solid $neutral-lighter-grey;
            padding: 6px 0;
            gap: 4px;

            &.progress-bar-item-active{
                border-bottom: 3px solid $primary-main;

                svg{
                    path{
                        stroke: $primary-main;
                    }
                }
            }
            &.progress-bar-item-finished{
                border-bottom: 3px solid $success-main;

                svg{
                    path{
                        stroke: $success-main;
                    }
                }
            }

            p{
                font-size: 12px;
                font-weight:600;
                color: $primary-main;
            }
        }
    }

    .signup-form-content{
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        width: 100%;
        box-sizing: border-box;

        @media screen and (min-width: 768px) {
            max-width: 50%;
        }

        .outlined-button{
            width: 100%;
            box-sizing: border-box;
        }

        h4{
            color: $neutral-d-grey;
        }
        p{
            color: $neutral-l-grey;
            font-size: 12px;
        }

        .input-box{
            width:100%;
            box-sizing: border-box;
        }

        .red-button{
            width: 100%;
            box-sizing: border-box;
        }

        .city-state{
            width: 100%;
            gap:16px;
            > div:not(:first-child) {
                margin-top: 16px;
            }
            @media (min-width: $medium) {
                display: flex;
                > div {
                    width: 50%;
                    margin-top: 0 !important;
                }
            }

        }

        .number-box{
            padding:8px;
            background-color: $neutral-silver;
            border-radius: 5px;
            width: 100%;
            box-sizing: border-box;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 8px;
            white-space: nowrap;

            .number-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px;
                background-color: #FFFFFF;
                border-radius: 5px;
                flex-direction: row;
                flex-wrap: nowrap;
                border: 1px solid $neutral-lighter-grey;
                box-sizing: border-box;
            }
        }

        .number-box-2{
            padding:8px;
            background-color: $neutral-silver;
            border-radius: 5px;
            width: 100%;
            box-sizing: border-box;
            
            .outlined-button{
                width: 100%;
                box-sizing: border-box;
                margin-bottom: 8px;
                padding: 8px;
                font-size: 14px;
            
            }

            .zipcode-container{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 8px;

                .number-item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px;
                    background-color: #FFFFFF;
                    border-radius: 5px;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    border: 1px solid $neutral-lighter-grey;
                    box-sizing: border-box;
                }
            }
        }
        //step 2
        .add-driver{
            width:100%;
            margin:8px;
            margin-top:0;
            box-sizing: border-box;


            .license-grid{
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;

                .inline-inputs {
                    width: 100%;
                    > div:not(:first-child) {
                        margin-top: 8px;
                    }
                    .react-datepicker-wrapper {
                        width: 100%;
                    }
                    @media (min-width: 768px) {
                        display: flex;
                        > div {
                            width: 50%;
                            margin-top: 0 !important;
                             &:not(:first-child) {
                                 margin-left: 8px;
                             }
                        }
                    }
                }

                .communication-number-wrapper{
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    width: 100%;
                    box-sizing: border-box;
    
                    .input-box{
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }
          
        }
        //step 3

        hr{
            width: 100%;
            box-sizing: border-box;
            border: 1px solid $neutral-lighter-grey;
        }
        h3{
            font-size: 16px;;
        }

        

        .schedule-section{
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 8px;
            padding:8px;
            background: $neutral-silver;
            border-radius: 5px;
        }
        .active-days{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            width: 100%;
            box-sizing: border-box;
            padding: 8px;

            .days{
                flex-direction: row;
                gap: 0px;
                justify-content: space-between;
                .day{
                    background: #FFFFFF;
                    padding: 8px;
                    border-radius: 5px;
                    border: 1px solid $neutral-lighter-grey;
                    letter-spacing: 1px;

                    &.active{
                        background-color: $primary-main;
                        p{
                            color: #FFFFFF;
                            font-weight: 600;
                            
                        }
                    }
                }
            }

            
        }
        .toggle-state{
            display: none;
        }

        .active-hours{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            width: 100%;
            box-sizing: border-box;
            padding:8px;

            .edit-days{
                display: flex;
                align-items: center;
                gap: 8px;
                p{
                    font-size: 14px;
                    color: $neutral-black;
                }
            }

        }

        .schedule-section{
            .name-fix{
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 8px;
            }

            .time-icon{
                width: 34px;
                height: 34px;
                
                border-radius: 5px;
                border: 1px solid $neutral-l-grey;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .time-input{
                border: 1px solid $neutral-l-grey;
                border-radius: 5px;
                display: flex;
                max-width: 4rem;
                height: 2rem;

            }
            .timebox{
                display: flex;
                align-items: center;
                gap:8px;
            }

            .name-fix{
                display: flex;
                align-items: flex-start;
                flex-direction: column;
            }

        }

    }

}

