// import variables
@import 'variables.scss';

html,
body {
    width: 100%;
    overflow-x: hidden !important;
}

#root {
    & > div:first-child {
        display: none !important;
    }
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
    grid-template-areas:
        'header'
        'main'
        'footer';
}

// set all text to Roboto
body,
a,
p,
span,
code,
button,
input,
textarea {
    font-family: $font-family;
}
// remove margin and padding from body and html
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
div,
textarea {
    margin: 0;
    padding: 0;
}

// set class headline1 to simi bold font size 28
.headline1 {
    font-size: 28px;
    font-weight: 600;
}
.headline2 {
    font-size: 20px;
    font-weight: 600;
}
.headline3 {
    font-size: 18px;
    font-weight: 600;
}
.headline4 {
    font-size: 16px;
    font-weight: 600;
}

.page-container {
    padding: 16px;
    height: 100%;
}

//style buttons
.red-button {
    background-color: $success-light;
    color: white;
    border: 1px solid $success-light;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
}

.outlined-button {
    background-color: white;
    color: $neutral-d-grey;
    border: 1px solid $neutral-d-grey;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    gap: 8px;
    font-size: 22px;
    font-weight: 400;
}

.light-shadow {
    box-shadow: 0px 8px 16px 0px #abbed166;
    padding: 8px;
    border: 1px solid #ededed;
    border-radius: 5px;
}

// Form & Input Styling
.input-group {
    width: 100%;
}
.input-box {
    padding: 16px;
    border-radius: 5px;
    border: 1px solid $neutral-l-grey;
    font-size: 16px;
    &.is-invalid {
        border-color: #dc3545;
    }
}
.input-box::placeholder {
    color: $neutral-l-grey;
    font-size: 16px;
    font-weight: 400;
}

.input-error {
    font-family: $font-family;
    color: #dc3545;
    margin-top: 0.25rem;
    font-size: 0.875em;
    align-self: start;
    text-align: left;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('./assets/svg/selectarrow.svg');
    background-repeat: no-repeat;
    background-position: right 8px center;
    &:invalid {
        color: $neutral-l-grey;
    }
}

.check-box {
    width: 24px;
    height: 24px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 1px solid $neutral-l-grey;
    background-color: $neutral-white;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    margin: 0;

    &::before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: $neutral-white;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.2s ease;
    }

    &:checked::before {
        margin: 4px;
        background-color: $primary-medium;
    }

    &:checked {
        border-color: $primary-medium;
    }
}

.error-message {
    color: #cc0033;
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    margin: 5px 0 0;
}
