@import '../../variables.scss';
// .cardinfo
//   .cardcode,
//   .month,
//   .year

.form-container {
    margin-bottom: 1rem;
    gap: 16px;
    > div {
        margin-top: 8px;
    }
    .inline-inputs {
        gap: 8px;
        > div:not(:first-child) {
            margin-top: 8px;
        }
        @media (min-width: $medium) {
            display: flex;
            > div {
                margin-top: 0 !important;
            }
            .card-code {
                width: 25%;
            }
            .expiry-date {
                width: 25%;
            }
            .zip-code {
                width: 50%;
            }
        }
    }
}

.form-h3 {
    margin-bottom: 1rem;
    text-align: center;
}

.payment-fieldset {
    padding: 0;

    div {
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        margin: 0.5rem;
        border: 3px solid #f78e1e;
        border-radius: 15px;
        max-width: 22rem;

        > input.payment-fieldset-input {
            flex: 1;
            border: none;
            background-color: transparent;
            font-size: 1.3rem;
            color: black;
            height: 30px;
            padding: 0;
            margin: 0;
            text-align: center;
            &:focus {
                outline: none;
            }
        }
        > input.payment-fieldset-input:checked::after {
            background-color: $primary-main;
        }

        > label.payment-label {
            flex: 6;
            font-size: 1.1rem;
            align-self: center;
            text-align: center;
        }
    }

    legend {
        font-size: 1.5rem;
        line-height: normal;
        color: black;
    }
}
