@import "../../variables.scss";


.location-form{
    display:flex;
    flex-direction: column;
    gap:16px;
}

.location-form-child{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;

    @media (min-width: 1024px){
        flex-direction: row;
    }
}

.confirmation-modal-overlay{
    display: flex;
    position: absolute;
    inset: 40px;
    border: 4px solid #0093d0;
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    z-index: 1000;
    max-width: 300px;
    max-height: 195px;
    margin-top: 50%;

    @media (min-width: 1024px) {
        margin-top: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }


    // top: '50%';
    // left: '50%';
    // right: 'auto';
    // bottom: 'auto';
    // margin-right: '-50%';
    // transform: 'translate(-50%, -50%)';
}

.confirmation-modal-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 16px;
}

.modal-buttons {
    display: flex;
    align-self: flex-end;
    justify-content: space-evenly;
    width: 100%;
    gap: 16px;
}