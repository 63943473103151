@import "../../variables.scss";

.login-box{
    padding: 16px;
    display:flex;
    flex-direction: column;
    gap: 16px;

    button{
        width:100%
    }

    .top-buttons{
        display: flex;
        justify-content: space-between;
        gap: 16px;
        align-items: center;

        
    }

    .sign-in{
        display:flex;
        flex-direction: column;
        gap: 16px;

        input{
            box-sizing: border-box;
            width: 100%;
        }

        a{
            align-self: flex-end;
            font-size: 12px;
            color: $primary-main;
            font-weight:600;
        }
        label{
            display: flex;
            white-space: nowrap;
            align-self: flex-start;
            align-items: center;
            gap:8px;
            font-size: 14px;

            input[type="checkbox"]{
                -moz-appearance:none;
                -webkit-appearance:none;
                -o-appearance:none;
                width:24px;
                height: 24px;
                outline: 1px solid $neutral-l-grey;
                border-radius: 5px;
                margin: 0;

                &:checked{
                    background-color: $primary-main;
                    outline: none;
                }
            }
        }

        p{
            font-size: 16px;
            color: $neutral-l-grey;
            font-weight:400;
            align-self: center;
        }
        .social-media{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
    }
}


.confirmation-box{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    padding: 16px;

    p{
        font-size: 16px;
        color: $neutral-l-grey;
        font-weight:400;
    }

    h3{
        font-size: 18px;
        font-weight: 600;
        color: $neutral-d-grey;
    }

    .confirmation-container{
        display:flex;
        flex-direction: row;
        gap: 8px;

        .confirmation-code-input{
            -moz-appearance:none;
            -webkit-appearance:none;
            -o-appearance:none;
            padding:16px;
            max-width: 20px;
            min-height:24px;
            outline: 1px solid $neutral-l-grey;
            border: none;
            border-radius: 5px;
            font-size: 14px;
            text-align: center;
        }
    }
    .red-button{
        width: 100%;
    }
    h4{
        font-size: 14px;
        font-weight: 400;
        color: $primary-main;
    }
}


.set-pass{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

   h3{
    color: $neutral-d-grey;
   }
   p{
    color: $neutral-d-grey;
   }

   .top-text{
       display: flex;
       flex-direction: column;
       align-items: center;
       text-align: center;
       gap: 8px;
    }

    .red-button{
        width: 100%;
    }

    .password-strength{
        height: 6px;
        border-radius: 5px;
        background-color: rgb(210, 210, 210);
    }

    .password-strength-1{
        background-color: $primary-main;
    }

    ul{
        color: $neutral-d-grey;
        padding-left: 25px;
        li{
            font-size:14px;
        }
    }

}

  