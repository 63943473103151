
//FIX PASSWORD BOXES
.input-wrapper{
    position:relative;
    width: 100%;
    box-sizing: border-box;

    .input-box{
        width: 100%;
        box-sizing: border-box;
    }

    .icon{
        position:absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
    }
}