@import '../../variables.scss';

.price-page-fix {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    .top-bar-service-selector {
        display: flex;
        width: 92vw;
        box-sizing: border-box;
        align-items: center;
        gap: 8px;
        .grey-circle {
            path {
                stroke: $neutral-lighter-grey;
            }
        }
        .dark-circle {
            path {
                stroke: $neutral-d-grey;
            }
        }

        .service-type-selector {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px;
            gap: 10px;
            width: 100%;
            box-sizing: border-box;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
            ::-webkit-scrollbar {
                width: 0 !important;
                background: transparent;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;

            .service-item {
                display: flex;
                align-items: center;
                gap: 4px;

                svg {
                    width: 20px;
                    height: 20px;
                }

                &.active-service-item {
                    color: $primary-main;
                    font-weight: 600;
                    border-bottom: 3px solid $primary-main;
                    padding: 6px 4px;

                    svg {
                        path {
                            fill: $primary-main;
                        }
                    }
                }
            }
        }
    }

    .service-box {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        width: 92vw;
        box-sizing: border-box;

        form {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        p {
            font-size: 14px;
            color: $neutral-l-grey;
        }
        .top-service-bar {
            display: flex;
            justify-content: space-between;
            .toggle-state {
                color: black;
                font-size: 16px;
            }
        }
        .fee-table {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-gap: 8px;

            .fee-item {
                display: flex;
                flex-direction: column;
                gap: 4px;
                label {
                    font-size: 14px;
                    font-weight: 600;
                }

                .fee-input {
                    position: relative;
                    width: 100%;
                    box-sizing: border-box;
                    .dollar-icon {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 4px;
                    }
                    input {
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .overview-chart {
            display: flex;
            flex-direction: column;
            background-color: $neutral-silver;
            padding: 8px;
            gap: 8px;
            border-radius: 5px;
            h3 {
                justify-self: center;
                color: $neutral-d-grey;
            }
            hr {
                border: 1px solid $neutral-lighter-grey;
                width: 100%;
                box-sizing: border-box;
                margin: 0;
            }
            .chart-item {
                display: flex;
                justify-content: space-between;
                color: $neutral-d-grey;
                .e-price {
                    font-weight: 600;
                }
            }
        }
    }
}
