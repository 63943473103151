@import '../../variables.scss';

.action-buttons {
    gap: 16px;
    white-space: nowrap;

    button {
        width: 100%;

        &:first-child {
            margin-bottom: 8px;
        }
    }

    @media (min-width: $medium) {
        display: flex;
        button {
            width: 50%;

            &:first-child {
                margin-bottom: 0;
            }
        }
    }
}

.page-container {
    padding: 16px;
    .location {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;

        @media (min-width: 1024px) {
            padding: 32px;
            width: 90%;
            margin-inline: auto;
            max-width: 1300px;
        }

        button {
            width: 100%;
            font-size: 18px;
        }

        h2 {
            font-size: 18px;
            font-weight: 600;
            // color: $neutral-d-grey;
        }

        .location-progress-bar {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 8px;
            .location-step {
                padding-bottom: 4px;
                border-bottom: 3px solid rgb(210, 210, 210);
                padding-left: 8px;
                padding-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                width: 100%;

                & span:last-child {
                    display: none;

                    @media (min-width: 1024px) {
                        display: block;
                    }
                }

                svg {
                    path {
                        stroke: $neutral-l-grey;
                        fill: $neutral-l-grey;
                    }
                }

                .margin-fix {
                    @media (min-width: 1024px) {
                        margin-right: 8px;
                    }
                }

                &.selected-step {
                    border-bottom: 3px solid $primary-main;
                    color: $primary-main;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        path {
                            stroke: $primary-main;
                            fill: $primary-main;
                        }
                    }

                    .color-fix {
                        height: 15px;
                        path {
                            stroke: $primary-main;
                        }
                    }

                    &.small-icon {
                        path {
                            fill: $primary-main;
                        }
                    }
                }
                &.finished-step {
                    border-bottom: 3px solid $success-main;
                    color: $success-main;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .car-details {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .car-details-inputs {
            display: flex;
            flex-direction: column;
            gap: 8px;

            @media (min-width: 1024px) {
                flex-direction: row;
                gap: 16px;
                margin-block: 16px;
            }
        }

        #charCount {
            align-self: flex-end;
            color: $neutral-d-grey;
            font-size: 14px;
        }
    }

    .input-box {
        width: 100%;
        box-sizing: border-box;
    }

    .phone-number {
        display: flex;
        flex-direction: column;
        gap: 8px;
        a {
            color: $primary-main;
            font-size: 14px;
            font-weight: 600;
            text-decoration: underline;
        }
    }

    .personal-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0.5rem 0;

        @media (min-width: $large) {
            flex-direction: row;
            gap: 16px;
            margin-block: 16px;
        }
    }

    .phone-input-container {
        margin: 1rem 0;
        h2 {
            margin-bottom: 0.5rem;
        }
    }

    .input-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        box-sizing: border-box;
        margin-block: 16px;

        @media (min-width: 1024px) {
            flex-direction: row;
        }
    }

    .w9-button {
        padding: 16px 32px;
        border-radius: 8px;
        border: 1px solid $neutral-d-grey;
        display: block;
        text-align: center;
        margin-block: 16px;
        cursor: pointer;
    }

    .payment-description {
        &__wrapper {
            display: block;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 16px;
            margin-block: 16px;
            place-items: center;

            @media (min-width: 1024px) {
                display: grid;
            }

            &__item {
                padding: 16px;
                background-color: #ededed;
                width: 95%;
                display: flex;
                flex-direction: column;
                gap: 4px;

                h2 {
                    margin-block: 8px;
                    font-size: 22px;
                }

                .between-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 8px;

                    &.dar {
                        font-weight: 600;
                        font-size: 18px;
                        margin-block: 8px;
                    }
                }
            }
        }
    }
}
