@import '../../variables.scss';

.provider-page {
    overflow-x: hidden;

    .title {
        position: relative;
        h3 {
            position: absolute;
            top: 0;
            left: 0;

            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
        .greeting {
            text-align: center;
        }
    }

    .texts {
        max-width: 300px;
        // margin-top: 1rem;
        border-radius: 5px;
    }

    .our-mission {
        background-color: $secondary-main;
        width: 100%;
        padding: 10px 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // gap: 16px;

        h3,
        h1 {
            color: $neutral-white;
        }

        @media (min-width: 764px) {
            padding: 45px;
        }

        .hero-wrapper {
            display: flex;
            flex-direction: column-reverse;
            gap: 16px;
            align-items: center;
            justify-content: center;
            // padding-inline: 15%;
            max-width: 1300px;
            margin-inline: auto;
            padding-block: 1rem;

            @media (min-width: 764px) {
                flex-direction: row;
                gap: 32px;
                padding-block: 0;
            }

            .about-text-our-mission {
                display: flex;
                flex-direction: column;
                gap: 8px;
                p {
                    color: $neutral-white;
                    font-size: 20px;
                }
            }

            img {
                width: 100%;
            }

            .outlined-button {
                background-color: $primary-main;
                color: white;
                border: 1px solid $primary-main;
                width: 100%;
                cursor: pointer;
                transition: 0.5s;
                margin-top: 16px;

                @media (min-width: 764px) {
                    width: 50%;
                }
            }
            .outlined-button:hover {
                color: #d1ebf5;
                border-color: #d1ebf5;
            }
        }
    }

    .our-vision {
        padding: 16px;
        text-align: center;
        display: grid;
        gap: 16px;
        .vision-board {
            display: grid;
            gap: 16px;
            grid-template-columns: 1fr 1fr;
            place-items: center;
            margin-inline: auto;
            max-width: 1300px;
            width: 100%;

            .vision-item {
                border: 1px solid $neutral-lighter-grey;
                border-radius: 11px;
                padding: 8px;
                display: grid;
                gap: 8px;
                justify-items: center;

                @media (min-width: 1024px) {
                    width: 100%;
                    max-width: 600px;
                    margin: 8px;
                }

                h3 {
                    font-size: 18px;
                }

                p {
                    font-size: 16px;
                }
            }
        }
    }

    .green-statement {
        background-color: $neutral-silver;
        padding: 16px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 16px;

        h1 {
            font-size: 26px;
        }

        .green-wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: center;
            justify-content: center;
            width: 90%;
            margin-inline: auto;
            max-width: 1300px;
            padding: 16px;

            @media (min-width: 764px) {
                flex-direction: row;
                gap: 32px;
                padding: 32px;

                img {
                    width: 50%;
                }
            }

            .green-item-wrapper {
                display: flex;
                flex-direction: column;
                gap: 16px;
                justify-content: center;

                @media (min-width: 764px) {
                    gap: 48px;
                }
            }
        }

        .green-item {
            gap: 16px;
            border-radius: 11px;
            padding: 32px;
            border: 1px solid $neutral-lighter-grey;
            align-items: center;

            svg {
                height: 50px;
                width: 50px;
            }

            p {
                font-size: 16px;
                text-align: left;
            }

            @media (min-width: 764px) {
                display: flex;
            }
        }

        .one {
            display: flex;
            flex-direction: column;
        }

        .three {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .action-button {
            align-self: center;
            background-color: $primary-main;
            color: white;
            border: 1px solid $primary-main;
            width: 100%;

            @media (min-width: 764px) {
                width: 50%;
            }
        }
    }
}
