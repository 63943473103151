.state-page-title {
    font-size: 100px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    padding: 1rem;
    background-color: rgba(3, 3, 3, 0.699);
    border-radius: 10px;

    @media (max-width: 767px) {
        font-size: 60px;
    }
}

.flag-banner-text {
    font-size: 20px;
    color: #fff;
    text-align: center;
    max-width: 800px;
    padding: 1rem;
    background-color: rgba(3, 3, 3, 0.699);
    border-radius: 10px;
}

.flag-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // box-shadow: inset 0 0 20px 10px white;
    height: 637px;

    @media (max-width: 767px) {
        max-height: 390px;
    }
}

.state-service-providers {
    gap: 1rem;

    .sp {
        color: #212121;
        font-size: 40px;
        background-color: rgba($color: #ffffff, $alpha: 1);
    }

    .service-providers {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 30px;
        padding-top: 0;
        flex-wrap: wrap;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    .service-provider-content {
        display: flex;
        flex-direction: column;
        border: #0093d0 2px solid;
        border-radius: 15px;
        padding: 1rem;
        max-width: 350px;
    }
}
