@import '../../../variables.scss';
.mobile-menu {
    .menu-links {
        position: absolute;

        top: 100%;
        left: 0;
        width: 100%;
        background-color: $neutral-white;
        border-top: 1px solid $neutral-lighter-grey;
        border-bottom: 1px solid $neutral-lighter-grey;
        transform: translateY(-100%);
        transition: opacity 0.3s, transform 0.3s;
        opacity: 0;
        height: 0;
        overflow: hidden;

        &.open {
            opacity: 1;
            height: auto;
            transform: translateY(0);
        }
        ul {
            list-style-type: none;
            padding: 16px;
            margin: 0;

            display: flex;
            flex-direction: column;
            align-items: center;

            li {
                padding: 16px 0px;
                width: 100%;
                box-sizing: border-box;
                border-radius: 5px;
                text-align: center;
                &.active {
                    background-color: $neutral-lighter-grey;
                    font-weight: 600;
                    color: $primary-main;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.mobile-menu-button {
    background-color: $primary-main;
    color: white;
    border: 1px solid $primary-main;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    font-size: 16px;
    font-weight: 400;
}
