@import '../../variables.scss';

.radio-choices {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
    .radio-choice {
        display: flex;
        padding: 1vh 2vh;
    }
}

.service-header {
    padding-bottom: 8px;

    @media (min-width: 768px) {
        padding-bottom: 16px;
    }
}

.service-selector {
    margin-top: 16px;

    h1 {
        color: $neutral-d-grey;
        font-size: 18px;
        > p {
            color: $neutral-d-grey;
        }
    }
    p {
        color: $neutral-l-grey;
        padding: 8px 0;
        font-size: 16px;
    }

    .service-type-buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
        .outlined-button {
            padding: 16px;
            justify-content: flex-start;
        }
        .outlined-button-red {
            background-color: white;
            color: $success-light;
            border: 2px solid $success-light;
            border-radius: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 16px;
            gap: 8px;
            font-size: 24px;
            font-weight: 400;
            path {
                fill: $success-light;
            }
        }
    }
    hr {
        margin: 16px 0;
    }

    .name-price {
        background-color: $neutral-silver;
        border-radius: 5px;
        padding: 8px;
        h3 {
            color: $neutral-d-grey;
            font-size: 18px;
            font-weight: 400;
        }
        p {
            color: $neutral-l-grey;
            font-size: 12px;
            font-weight: 400;
        }

        .price-input {
            width: 100%;
            background-color: white;
            border: 1px solid $neutral-l-grey;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
                padding: 16px;
            }
        }
    }

    .continue-button {
        margin-top: 16px;
        width: 100%;
    }
}

//desktop fix

@media (min-width: 768px) {
    .service-selector {
        // background-color: white;
        // max-width: 650px;
        // width: 650px;
        // padding: 16px;

        h1 {
            font-size: 28px;
        }

        .service-type-buttons {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
