// set neutral color
$neutral-black: #212121;
$neutral-d-grey: #4D4D4D;
$neutral-grey: #717171;
$neutral-l-grey: #89939E;
$neutral-lighter-grey: #D6D6D6;
$neutral-silver: #F5F7Fa;
$neutral-white: #FFFFFF;

// set primary color
$primary-dark-main: #770000;
$primary-main: #0093d0;
$primary-medium: #A7381F;
$primary-light: #BF5331;
$primary-lighter: #DA7448;
$primary-lightest: #FCB574;

// set secondary color
$secondary-dark-main: #186D39;
$secondary-main: #F78F1E;
$secondary-medium: #45B17E;
$secondary-light: #60D4AA;
$secondary-lighter: #7EF0D5;
$secondary-lightest: #CCFFF3;

// set success color
$success-dark-main: #115B43;
$success-main: #1B6E53;
$success-medium: #009262;
$success-light: #f78e1e;
$success-lighter: #7EF0C0;
$success-lightest: #F1FBF8;

// set warning color
$warning-dark-main: #C33025;
$warning-main: #E01507;
$warning-medium: #E02B1D;
$warning-light: #FF5A4F;
$warning-lighter: #F0857D;
$warning-lightest: #FFF1F0;

// Set Font Family Roboto

$font-family: 'Roboto', sans-serif;


// min-width media breakpoints
$x-small: 576px;
$small: 768px;
$medium: 992px;
$large: 1200px;
$x-large: 1400px;