// import variables
@import '../../../variables.scss';

.terms-form-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    @media screen and (min-width: 768px) {
        max-width: 50%;
    }

    .outlined-button {
        width: 100%;
        box-sizing: border-box;
    }

    h4 {
        color: $neutral-d-grey;
    }
    p {
        color: $neutral-l-grey;
        font-size: 12px;
    }

    .input-box {
        width: 100%;
        box-sizing: border-box;
    }

    .red-button {
        width: 100%;
        box-sizing: border-box;
    }
    > div:first-child {
        width: 85vw;
        height: 60vh;
        overflow: auto;
        border: 1px solid black;

        .ContractText {
            line-height: 15pt;
        }
    }
}
